@tailwind base;
@tailwind components;
@tailwind utilities;

.mini-video video {
  object-fit: contain !important;
}

.x-scroll {
}
