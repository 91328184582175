.message-input-form {
  flex: auto;
  padding: 1rem 0 1rem 1rem;
}

span.text-input {
  display: flex;
  flex-grow: 1;
}

.text-input input {
  width: 100%;
  @apply border border-solid border-dgray-300 text-base rounded-lg shadow-none !py-2.5 !px-3.5;
}

.message-input-container {
  display: flex;
  flex-flow: row;
  margin-top: auto;
  margin-bottom: 0;
  min-height: 4rem;
}

.message-input-container .write-link.attach {
  margin: 1rem 1rem auto 0.5rem;
}

.message-input-container .attachment-preview {
  display: flex;
}

.message-input-container .attachment-preview span {
  margin: auto 0;
}

.message-input-container.join-channel-message {
  display: flex;
  justify-content: center;
  align-items: center;
}
