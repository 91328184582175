.message-list-container {
  position: relative;
  overflow-y: scroll;
  height: 100%;
  max-height: calc(100vh);
  display: flex;
  flex-direction: column;

  width: 362px;
  @apply bg-white border border-solid border-gray-200 rounded-xl text-center;

  /* QWE border border-solid border-gray-200  */
}

.message-list-header {
  background-color: inherit;
  text-align: center;
  padding: 0.76rem;
  position: sticky;
  z-index: 2;
  top: 0;
  right: 0;
  left: 0;
}

.chat-bubble {
  @apply !bg-transparent !text-dgray-900 !p-0 !shadow-none !w-full !max-w-full;
}

/* hiding the actions div */
.chat-bubble + div {
  @apply !hidden;
}

.chat-bubble--outgoing {
  @apply !ml-10;
}

.chat-bubble .ch-header {
  @apply !block !m-0;
}

@media screen and (max-width: 768px) {
  .message-list-container {
    position: fixed;
    /* top: 80px; */
    min-height: 80vh;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    width: 100vw !important;
    height: auto !important;
  }
}
